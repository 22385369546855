body {
    position: relative;
    // font-family: "roboto", sans-serif;
  }
  
  .container {
    position: relative;
  }
  
  .link {
    color: $color-primary;
    cursor: pointer;
  }
  
  // Sticky header and subtab
  #main-header {
    position: sticky;
    background-color: $color-white;
    top: 0;
    z-index: 10;
  }
  
  .page-title-info .dropdown-menu {
    max-height: 250px;
    overflow: auto;
  }
  
  .font-10 {
    font-size: 10px;
  }
  
  .font-12 {
    font-size: 12px;
  }
  
  .font-13 {
    font-size: 13px;
  }

  .z-0{
    z-index: 0;
  }
  
  // Grid freeze css
  .btn-outline-secondary {
    &:focus,
    &.focus {
      border-color: $color-light-blue;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba($color-primary, 0.25);
    }
  }
  
  #page-body {
    .page-content {
      display: none;
  
      &.active {
        display: block;
      }
  
      .object-name {
        word-break: break-word;
      }
    }
  }
  
  #form-diag-cover {
    display: none;
  
    &.active {
      display: block;
      position: fixed;
      left: 20px;
      top: 20px;
      bottom: 20px;
      right: 20px;
      z-index: 101;
      background-color: $color-white;
    }
  }
  
  #form-diag-overlay {
    display: none;
  
    &.active {
      display: block;
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      opacity: 0.5;
      background-color: $color-black;
      z-index: 100;
    }
  }
  
  // Fix lookup PageTitle Tab display issue
  .no-internet {
    position: fixed;
    top: 0;
    width: 100%;
  }
  
  .text-wrap::after {
    display: none;
  }
  
  .text-wrap-dropdown {
    min-width: auto !important;
    border: $color-primary solid 1px !important;
    padding: 0 !important;
  
    a[data-text="clip"]::before{
      position: absolute;
      left: 10px;
      top: 6px;
      width: 20px;
      height: 20px;
      color: $color-white;
      content: "\f00c";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      color: inherit;
      font-size: 10px;
    }
  
    a.dropdown-item {
      position: relative;
  
      &:hover {
        background: $color-primary;
        color: $color-white;
      }
    }
  }

  .wrap {
     .text-wrap-dropdown{
      a[data-text="wrap"]::before {
        position: absolute;
        left: 10px;
        top: 6px;
        width: 20px;
        height: 20px;
        color: $color-white;
        content: "\f00c";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        color: inherit;
        font-size: 10px;
      }
    
       a[data-text="clip"]::before {
        display: none;
      }
     }
  }
  
  .event-external-link {
    float: right;
    right: 16px;
    top: 16px;
  }
  
  .user-menu .dropdown-menu {
    .fa-duotone {
      color: $color-primary !important;
    }
  }
  
  /* Mobile version css Start */
  @media (max-width: 370px) {
    .General-setting .daytiming {
      flex-direction: column;
    }
  
    .General-setting .daytiming > .col > div {
      margin: 0 !important;
    }
  }
  
  @media (max-width: 767px) {
    #main-header {
      position: sticky;
      background-color: $color-white;
      top: 0;
      z-index: 2;
    }
  
    #searchDropdown {
      display: none;
    }
  
    .task-quick-view {
      position: fixed;
      right: 0;
      z-index: 1000;
    }
  
    .user-menu .dropdown-menu {
      z-index: 1000 !important;
    }
  
    /*For grid table*/
  
    /* Force table to not be like tables anymore */
    .close-mob-filter {
      position: absolute;
      right: 4px;
      top: 0px;
      z-index: 4;
    }
  
    .gird-button-list {
      .btnFilter,
      .btnEdit,
      .search-in-list,
      //.text-search-area,
      .btnDelete,
      //.btnSearch,
      .btn.move-btn,
      .btn.copy-btn,
      .btn.copyTo-btn {
        display: none;
      }
  
      .show-list-view {
        display: inline-block;
      }
  
      .select-venue.d-inline-block {
        display: none !important;
      }
    }
  
    .page-top-button {
      .btn,
      .DashboardList .btn.btn-add-object,
      .TaskListGrid .btnListView,
      .btn.btn-edit-object.btnCloneProfile,
      .btn[data-fn="_campaign.InitCampaignAttribute()"],
      [data-uid*="WorkflowRulesDetail"] .btn.btn-edit-object,
      [data-uid*="UserDetail"] .btn.btn-edit-object {
        display: none !important;
      }
  
      .btn.btn-add-object,
      .btn.close-task-quick-view,
      .btn.btn-edit-object,
      .btn.btnRefresh,
      [data-restriction="Create"],
      .upload-button,
      .newfolder,
      .btn-sm.btn-primary.dropdown-toggle,
      .btn-addSharing,
      [data-uid^="ActivityDetail"] [data-fn="_calendar.ActivityDetailEdit(self)"],
      .btn[data-fn^="_eventsCalendar.EditEventActivityFromOtherSource(self)"],
      [data-uid*="WorkflowRulesDetail"] .btn.btn-deactivate-rule,
      [data-uid*="WorkflowRulesDetail"] .btn.btn-activate-rule,
      .btn.create-new-action,
      .btn.btnBlockUser.active,
      .btn.btnUnblockUser.active,
      .btn.btn-outline-primary[data-fn="_automation.OpenWorkflowActionForm(self)"] {
        display: inline-block !important;
      }
  
      .btn.btn-deactivate-rule[disabled="disabled"],
      [data-uid*="WorkflowRulesDetail"] .btn.btn-activate-rule[disabled="disabled"] {
        display: none !important;
      }
    }
  
    /*Detail page css*/
    .tab-pane > .table-sec .card-header {
      padding-top: 0;
    }
  
    #side-content .blankheight {
      min-height: calc(100vh - 330px) !important;
    }
  
    .General-setting > .col.col-auto,
    .setting-tab > .col.col-auto,
    .event-setting > .col.col-auto,
    .marketing-setting > .col.col-auto,
    .terms-condition > .col.col-auto {
      width: 100% !important;
    }
  
    .General-setting > .col.col-auto[id^=item-]:nth-child(2),
    .General-setting > .col.col-auto[id^=item-]:nth-child(3),
    .General-setting > .col.col-auto[id^=item-]:nth-child(4) {
      margin-top: -110px;
      padding-top: 110px;
    }
  
    .control-panel .box,
    .integration-plugins .box {
      max-width: 100% !important;
    }
  
    .user-working-days {
      .starttime,
      .endtime {
        max-width: 105px !important;
      }
    }
  
    .help-left-panel {
      display: none;
    }
  
    .apdCountry {
      max-width: 150px;
    }
  
    .campaign-container .campaign {
      display: block !important;
      white-space: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
    }
  
    .campaign-container .campaign li {
      display: inline-block !important;
    }
  
    .upload-section {
      max-width: 300px;
      left: calc(100% - 320px) !important;
    }
  
    [data-uid*="ProfilePictureEdit"] .form-group .row,
    [data-uid*="EventUploadListPicture"] .form-group .row,
    [data-uid*="EventUploadDetailPicture"] .form-group .row {
      display: block !important;
    }
  
    .cal-body .col {
      margin: 2px;
    }
  
    .General-setting .active-days {
      border-radius: 5% !important;
    }
  
    .General-setting,
    .employee-setting,
    .setting-tab,
    .unsubscribePageTemplate,
    .marketing-setting,
    .terms-condition {
      position: relative;
      z-index: 0;
    }
  
    .employee-setting > .col.col-auto {
      width: 100% !important;
    }
  
    [data-uid="ControlPanel"] [page-name="ObjectSchemaList"] {
      display: none;
    }
  
    /*Secondary Nabvar*/
    .General-setting .daytiming .input-group-prepend .btn {
      z-index: 0;
    }
  
    /*Dashboard css*/
    .dashboard-grid {
      padding: 0 !important;
    }
  
    .dashboard-grid .DashboardLoading {
      z-index: 0;
    }
  
    .multiselect-container.dropdown-menu {
      max-width: calc(100vw - 60px) !important;
    }
  
    .img-profile-calender-initials {
      height: 30px !important;
      width: 30px !important;
      font-size: 14px !important;
      padding-top: 4px !important;
    }
  
    .uploaded-files li.list-group-item {
      z-index: 0;
    }
  
    .uploaded-files li.list-group-item {
      z-index: 0 !important;
    }
  
    .campaign-container.p-3 {
      padding: 0 !important;
    }
  
    .event-external-link {
      float: unset !important;
      right: unset !important;
      top: unset !important;
    }
  
    [data-field="ExternalRegistrationLink"] > label.small {
      float: unset !important;
    }
  
    [data-field="ExternalRegistrationLink"] > div.text-gray-900 {
      float: unset !important;
      margin-top: unset !important;
      margin-right: unset !important;
    }
  
    [data-field="ExternalRegistrationLinkBox"] > label.small,
    .btnEditUnsubscribePage,
    .dropdown-item.btnStoreMenu {
      display: none !important;
    }
  
    .folder-owner.row b {
      font-size: 0.85rem;
      font-weight: normal;
    }
  
    .highcharts-exporting-group,
    .main-drive-spacebar {
      display: none !important;
    }
  
    .main-drive .main-drive-list tr td:nth-child(2)::before {
      content: 'Name';
    }
  
    .main-drive .main-drive-list tr td:nth-child(3)::before {
      content: 'Size';
    }
  
    .main-drive .main-drive-list tr td:nth-child(4)::before {
      content: 'Date';
    }
  
    .main-drive .main-drive-list tr td:nth-child(5)::before {
      content: 'Owner';
    }
  
    .main-drive .main-drive-list tr td:first-child {
      display: block !important;
    }
  }
  
  /* Mobile version css end */
  .show-map {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: none;
    transition: all 0.3s;
  }
  
  .close-map {
    right: 0;
    margin-right: 10px;
    width: 40px;
    bottom: 105px;
    height: 40px;
    font-size: 20px;
    line-height: 30px;
    position: absolute;
    border-radius: 2px 2px 0 0;
  }
  
  .grid-filter-sec {
    display: none;
    transition: all 0.3s;
  }
  
  .custom-scrollbar {
    &::-webkit-scrollbar {
      width: 6px;
    }
  
    .scroll-horizontal::-webkit-scrollbar {
      width: unset;
      height: 9px;
    }
  
    &::-webkit-scrollbar-track {
      background: $color-white;
    }
  
    &::-webkit-scrollbar-thumb {
      background: $color-light-gray;
    }
  
    &::-webkit-scrollbar-thumb:hover {
      background: $color-secondary;
    }
  }
  
  .cal-body .col {
    border: none !important;
    font-size: 75%;
    font-weight: 600;
    padding: 6px 0px;
    cursor: pointer;
  }
  
  .secondary-label {
    display: none;
  }
  
  .unread-icon {
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .item-selected {
    background: $color-secondary;
    color: $color-white;
  
    .secondary-label {
      color: rgba($color-white, 0.6);
    }
  
    &.item-exists::after {
      background: $color-white;
    }
  }
  
  .current-day {
    background: $color-primary;
    border-radius: 50%;
    color: $color-white;
  
    &:hover {
      background-color: $color-primary-hover !important;
    }
  
    &.item-selected {
      background: $color-primary;
    }
  
    &.item-exists::after {
      background: $color-white;
    }
  }
  
  button.btn.menu-pin {
    position: absolute;
    right: 0;
    top: 0;
    color: rgba($color-white, 0.7);
  }
  
  .btn-light {
    background: $color-gray-1;
    border-color: $color-gray-1;
  }
  
  .cursor-move {
    cursor: move;
  }
  
  .cursor-pointer {
    cursor: pointer;
  }
  
  .align-items-stretch {
    align-items: stretch;
  }
  
  .object-detail-right .collapsed i {
    transform: rotate(180deg);
    transition: all 0.35s;
  }
  
  .object-detail-right .card:first-child {
    margin-top: 0 !important;
  }
  
  .simple-list {
    ul {
      list-style: none;
      padding-left: 0px;
      margin: 0;
  
      li a {
        padding: 0.6rem 1rem;
        display: block;
        border-bottom: 1px solid $color-gray-2;
        text-decoration: none;
        transition: all 0.2s;
  
        &:hover {
          background: $color-primary;
          color: $color-white;
        }
      }
    }
  }
  
  .changed {
    .msp-ctrl,
    input,
    select,
    textarea,
    .msp-ctrl-lookup,
    .grid-name,
    .field-block .card,
    .field-item,
    .tab-item {
      background: $light-sky-blue-2;
    }
  }
  
  @keyframes arrowBranch {
    0% {
      top: 0;
    }
  
    50% {
      top: 5px;
    }
  
    100% {
      top: 0;
    }
  }
  
  @keyframes swing {
    0% {
      -webkit-transform: rotateZ(-5deg);
      transform: rotateZ(-5deg);
    }
  
    50% {
      -webkit-transform: rotateZ(5deg);
      transform: rotateZ(5deg);
    }
  
    100% {
      transform: rotateZ(-5deg);
      -webkit-transform: rotateZ(-5deg);
    }
  }
  
  #notificationDropdown {
    i.bell-swing {
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;
      animation: road-animates 1s linear infinite;
      -webkit-animation: swing 1s linear infinite;
      -moz-animation: swing 1s linear infinite;
      -o-animation: swing 1s linear infinite;
      transform-origin: center top;
      -moz-transform-origin: center top;
      -webkit-transform-origin: center top;
      color: $color-secondary;
    }
  
    .badge {
      font-size: 85%;
    }
  }
  
  @keyframes leftRight {
    0% {
      left: -100px;
      opacity: 0;
      background: $color-white;
    }
  
    75% {
      background: rgb(232, 235, 255);
    }
  
    100% {
      left: 0;
      opacity: 1;
    }
  }
  
  #myTabContent .dropdown p {
    @include text-hidden;
    width: 100%;
  }
  
  .pr-15 {
    padding-right: 15px;
  }
  
  #cal-tab .nav-link,
  .report-tab .nav-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.7109375rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }
  
  .nav-pills {
    .nav-link.active,
    .show > .nav-link {
      color: $color-white;
      background-color: $color-primary;
    }
  }
  
  .item-exists {
    position: relative;
  
    &::after {
      position: absolute;
      content: "";
      width: 3px;
      height: 3px;
      background: $color-primary;
      border-radius: 50%;
      bottom: 3px;
      left: 13px;
    }
  }
  
  /* ,.email-section .email:hover  */
  [data-ui="multilookup"] {
    .lookup-display-record:hover {
      color: $color-white !important;
    }
  
    .btn-outline-secondary:hover .lookup-display-record {
      color: $color-white !important;
    }
  }
  
  .query-list {
    margin-left: 9px;
    margin-top: 15px;
  }
  
  .multiselect-container {
    &.dropdown-menu {
      max-height: 300px;
      overflow: auto;
      /* Width added for production issue - 4425 */
      width: max-content;
  
      li {
        padding: 0 7px 0 0;
      }
    }
  
    &.search-multiselect {
      min-height: 300px;
      width: 100%;
      max-width: 100%;
    }
  }
  
  .tbl-module-list .multiselect-container.dropdown-menu {
    height: auto !important;
  }
  
  /* End */
  
  /*#region Suraj*/
  .img-editor {
    background-color: $color-white;
    z-index: 9999;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  
    .header {
      background-color: $color-white;
      text-align: right;
      padding: 8px 12px;
  
      .settings {
        text-align: left;
        float: left;
      }
    }
  
    .body {
      background-color: rgba($color-black, 0.9);
      border: 0;
      height: calc(100% - 110px);
      width: 100%;
      position: relative;
      overflow: hidden;
  
      .image-selector {
        position: absolute;
        border: 2px solid rgba(255, 255, 255, 0.75);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        overflow: hidden;
  
        .img {
          opacity: 1;
          position: absolute;
        }
      }
  
      .uploaded-image {
        display: inline-block;
        opacity: 0.4;
        margin: auto;
        text-align: center;
      }
    }
  
    #img-drag-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      /* text-align:center;  */
    }
  
    .footer {
      background-color: $color-white;
      padding: 8px 12px;
    }
  
    .slidecontainer {
      width: 100%;
    }
  
    .slider {
      -webkit-appearance: none;
      z-index: 2;
      position: relative;
      appearance: none;
      width: 100%;
      height: 6px;
      background: transparent;
      outline: none;
      opacity: 1;
      -webkit-transition: 0.2s;
      transition: opacity 0.2s;
  
      &:hover {
        opacity: 1;
      }
  
      &::-webkit-slider-thumb:hover {
        background: $color-black;
      }
  
      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 25px;
        height: 25px;
        background: $color-primary;
        cursor: pointer;
        border-radius: 50%;
        z-index: 10;
        position: relative;
        transition: all 0.3s;
      }
  
      &::-moz-range-thumb {
        -moz-appearance: none;
        appearance: none;
        width: 25px;
        height: 25px;
        background: $color-primary;
        cursor: pointer;
        border-radius: 50%;
        z-index: 10;
        position: relative;
        transition: all 0.3s;
      }
    }
  
    .range-cover {
      position: relative;
      width: 40%;
      display: inline-block;
    }
  
    .range-filler {
      position: absolute;
      z-index: 1;
      left: 0;
      top: 9px;
      height: 6px;
      background-color: $color-primary;
    }
  
    .slider-range-filler {
      position: absolute;
      width: 100%;
      z-index: 0;
      left: 0;
      top: 9px;
      height: 6px;
      background-color: $color-light-gray;
    }
  }
  
  .profile-pic {
    position: relative;
  
    &:hover {
      .profile-img-edit {
        opacity: 1;
      }
  
      .edits {
        display: block;
      }
    }
  }
  
  .profile-img-edit {
    padding: 4px;
    position: absolute;
    right: 0;
    bottom: 0;
    display: block;
    color: $color-white;
    transition: all 0.2s;
    opacity: 0;
  }
  
  .pac-container {
    z-index: 10001;
  }
  
  .search-controls {
    text-align: center;
    padding: 16px;
    display: flex;
    flex-direction: column;
  
    .txtSearch::placeholder {
      color: $color-white;
    }
  }
  
  .admin-controls-list .control-item,
  .integration-plugins .control-item {
    cursor: pointer;
  }
  
  .user-replace-settings .moudules-list .modules {
    padding: 0;
    margin: 0;
    list-style: none;
    max-height: 250px;
    overflow: auto;
  }
  
  .operavipupcomingstay {
    cursor: pointer;
  }
  
  /*#endregion Suraj*/
  .pleaseWait {
    display: none;
  }
  
  .userTimingTable .userDetail {
    font-style: bold;
  }
  
  .fas,
  .fa,
  .far {
    font-weight: 800 !important;
  }
  
  .close {
    color: $color-primary;
  }
  
  .text-black {
    color: $color-black !important;
  }
  
  .comp-signature .tool {
    position: absolute;
    right: 0;
    bottom: 0;
  }
  
  .dropdown-nav.cal-month-menu,
  .dropdown-nav.cal-year-menu {
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 0;
    height: auto;
    font-size: 16px;
    color: $color-black;
    height: 22px;
    transition: all 0.2s;
    margin-top: -2px;
  }
  
  .monthYear {
    height: 22px;
  }
  
  .dropdown-nav {
    &.cal-month-menu,
    &.cal-year-menu {
      -webkit-appearance: none;
      -moz-appearance: none;
      padding: 0;
      height: auto;
      font-size: 16px;
      color: $color-black;
      height: 22px;
      transition: all 0.2s;
      margin-top: -2px;
    }
  
    &.cal-month-menu {
      width: 50px;
      margin-left: 8px;
    }
  
    &.cal-year-menu {
      width: 70px;
    }
  }
  
  .reset-userFilter,
  .reset-allEventFilters {
    color: $color-primary;
    float: right;
    cursor: pointer;
  }
  
  .text-ellipse {
    @include text-hidden;
  }
  
  .edits {
    padding: 4px;
    position: absolute;
    right: 0;
    top: 0;
    display: none;
    background: $color-primary;
    color: $color-white;
  }
  
  #progress-bar-per {
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
  
    background: rgba($color-white, 0.95);
  
    svg {
      width: 200px;
      position: relative;
      margin: auto;
    }
  }
  
  .user-working-days {
    .starttime,
    .endtime {
      max-width: 140px;
    }
  
    .endtime {
      margin-left: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }
  
  .permit-users-list {
    li {
      display: inline-block;
      padding-right: 6px;
      position: relative;
  
      &::after {
        position: relative;
        content: ",";
      }
  
      &:last-child::after {
        display: none;
      }
    }
  }
  
  .minimize-panel {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    width: 100%;
  }
  
  .participant-list {
    max-height: 180px;
    overflow-y: auto;
  
    li {
      margin-bottom: 2px;
  
      &:hover .participant-action {
        opacity: 1;
      }
    }
  }
  
  .participant-action {
    opacity: 0;
    transition: all 0.3s;
  
    .dropdown-toggle::after {
      display: none;
    }
  }
  
  .activity-chart {
    .nav-item {
      color: $color-black;
      border: 0;
      border-bottom: 2px solid transparent;
      font-weight: normal;
      padding: 0.2rem 0.35rem;
      transition: all 0.2s;
      cursor: pointer;
  
      &.active {
        color: $color-primary;
        border: 0;
        border-bottom: 2px solid $color-primary;
        cursor: pointer;
      }
    }
  
    .collapse-head.collapsed .nav-item {
      display: none;
    }
  }
  
  .full-screen {
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    font-size: 1.5em;
    max-height: 100%;
  }
  
  /*===================Responsive breakpoints=======================*/
  @media (min-width: 320px) {
    .main-drive .folder-box,
    .main-drive .file-box,
    ul.templateList.marketing-list li,
    .div-img-list {
      max-width: 50%;
      min-width: 50%;
    }
  }
  
  @media (min-width: 576px) {
    .main-drive .folder-box,
    .main-drive .file-box,
    ul.templateList.marketing-list li {
        max-width: 50%;
        min-width: 50%;
      }
    
      .div-img-list {
        max-width: calc(50% - 1rem);
        min-width: calc(50% - 1rem);
      }
  
    .main-drive .file-box {
      height: 50%;
    }
  }
  
  @media (min-width: 768px) {
    .main-drive .folder-box,
    .main-drive .file-box,
    ul.templateList.marketing-list li {
        max-width: 25%;
        min-width: 25%;
      }
    
      .div-img-list {
        max-width: calc(33.3% - 1rem);
        min-width: calc(33.3% - 1rem);
      }
  
    .main-drive .file-box {
      height: 25%;
    }
  }
  
  @media (min-width: 992px) {
    .main-drive .folder-box,
    .main-drive .file-box,
    ul.templateList.marketing-list li {
        max-width: 20%;
        min-width: 20%;
      }
    
      .div-img-list {
        max-width: calc(25% - 1rem);
        min-width: calc(25% - 1rem);
      }
  
    .main-drive .file-box {
      height: 20%;
    }
  }
  
  @media (min-width: 1200px) {
    .main-drive .folder-box,
    .main-drive .file-box,
    ul.templateList.marketing-list li {
        max-width: 16.6%;
        min-width: 16.6%;
      }
    
      .div-img-list {
        max-width: calc(20% - 1rem);
        min-width: calc(20% - 1rem);
      }
  
    .main-drive .file-box {
      height: 16.6%;
    }
  }
  
  .page-title-info h5,
  .object-name {
    word-break: break-all;
  }
  
  .navbar-search {
    input:focus,
    button:focus {
      box-shadow: none;
    }
  }
  
  .focus-shadow.active {
    box-shadow: 0 0 0 0.2rem rgba($color-primary, 0.25);
    border-radius: 0.35rem;
    transition: all 0.2s;
  }
  
  .social-icons a,
  .template-button a,
  .td-col a {
    position: relative;
    display: inline-block;
  }
  
  .clicksMap .template-mail-body a {
    position: relative;
    overflow: visible !important;
  }
  
  .link-count-style {
    padding-top: 3px;
    font-size: 10px;
    background: $color-danger;
    border-radius: 50%;
    color: $color-white;
    width: 18px;
    height: 18px;
    display: inline-block;
    text-align: center;
    position: absolute;
    top: -7px;
    right: -8px;
    vertical-align: sub;
    position: absolute;
  }
  
  /* .template-button .link-count-style {
    top: -16px;
    right: -28px;
  } */
  
  .notification-div {
    max-height: 400px;
    overflow: auto !important;
    padding-top: 0 !important;
  }
  
  .marketing-editor {
    .block {
      display: none;
  
      &.active {
        display: block;
      }
    }
  }
  
  .mail-box {
    background-color: $color-blue-tint;
    border: 1px solid $color-gray-2;
    border-top: 0;
    padding: 0;
    margin-bottom: 20px;
  }
  
  .mail-box-header {
    background-color: $color-white;
    border: 1px solid $color-gray-2;
    border-bottom: 0;
    padding: 20px;
  }
  
  .mail-box-header h2 {
    margin-top: 0;
    width: calc(100% - 134px);
    /* overflow: hidden; */
    /* height: 32px; */
    text-overflow: ellipsis;
  }
  
  .mail-body {
    border-top: 1px solid $color-gray-2;
    padding: 20px;
    word-break: break-all;
  }
  
  .mail-text {
    border-top: 1px solid $color-gray-2;
  
    .note-toolbar {
      padding: 10px 15px;
    }
  
    .note-editor .note-toolbar {
      background-color: $color-blue-tint;
    }
  }
  
  .mail-body .form-group {
    margin-bottom: 5px;
  }
  
  .mail-attachment {
    border-top: 1px solid $color-gray-2;
    padding: 20px;
    font-size: 12px;
  
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }
  
  .mailbox-content {
    background: none;
    border: none;
    padding: 10px;
  
    .tag-list li a {
      background: $color-white;
    }
  }
  
  .fileName {
    li {
      display: inline-block;
      margin: 5px;
      color: $color-primary;
      border: 1px solid $color-primary;
      border-radius: 0.35rem;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  
      a {
        padding: 0.375rem 0.75rem;
        display: block;
  
        i {
          margin-right: 4px;
        }
      }
  
      &:hover {
        background: $color-primary;
        color: $color-white;
  
        a {
          color: $color-white;
          text-decoration: none;
        }
      }
    }
  }
  
  @media print {
    .mail-content {
      max-width: 100% !important;
      width: 100%;
    }
  
    .printemail,
    .mail-print-btn {
      display: none !important;
    }
  }
  
  .notification-item {
    word-break: break-word;
  }
  
  .msp-view-collection {
    overflow-y: auto;
  }
  
  .bell-size {
    font-size: 1.2rem;
  }
  
  .msp-coll-lbl {
    position: relative;
  
    &::after {
      position: absolute;
      content: "\f107";
      right: 8px;
      top: 4px;
      font-weight: bold;
      font-family: "Font Awesome 5 Free";
    }
  }
  
  .img-profile-glow {
    box-shadow: 0 0 0 0.2rem rgba($color-primary, 0.25);
  }
  
  .img-profile-container:hover .img-profile .img-profile-task {
    box-shadow: 0 0 0 0.2rem rgba($color-primary, 0.25);
  }
  
  .img-profile-container .user-name {
    max-width: 150px;
    @include text-hidden;
    position: relative;
    top: 6px;
  }
  
  .object-picture:hover {
    box-shadow: 0 0 0 0.2rem rgba($color-primary, 0.25);
    cursor: pointer;
  }
  
  /* Tag Designing**/
  .select-all-grid {
    float: left;
    padding: 0.2rem 1.2rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    background-color: $color-white;
    border-color: rgba($color-primary, 0.07);
    z-index: 101;
    position: relative;
  }
  
  .select-all-grid-text:not(.selected) {
    cursor: pointer;
    text-decoration: underline;
    color: $color-primary;
  }
  
  .clear-selection-grid {
    cursor: pointer;
    color: $color-danger;
    text-decoration: underline;
  }
  
  .singleDeleteBtn {
    color: $color-danger;
    margin-left: 10px;
  }
  
  .custom-tag-control {
    padding-left: 2rem;
    display: block;
  }
  
  /* Tag Designing Ends**/
  .exp-time-pickup {
    max-width: 250px;
  }
  
  .lh-1 {
    line-height: 1;
  }
  
  .op-color-picker {
    position: relative;
  
    .fas {
      color: $color-black;
      padding: 2px;
      opacity: 0.75;
      background: $color-white;
    }
  }
  
  .content-editable {
    border: 1px solid $color-gray-1;
    border-radius: 4px;
    padding: 4px;
  
    &:focus {
      color: $color-light;
      border-radius: 4px;
      background-color: $color-white;
      border-color: $color-light-blue;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba($color-primary, 0.25);
      padding: 4px;
    }
  }
  
  .disable-build-ctrl {
    pointer-events: none;
    opacity: 0.8;
  }
  
  .grid-activity-pending {
    color: var(--primary);
  }
  
  .help-left-panel .shadow {
    height: calc(100vh - 81px);
    overflow: auto;
  }
  
  .help-sub-topic > a {
    display: block;
    padding-left: 14px;
  }
  
  .grid-activity-locked {
    cursor: no-drop;
    border-left: solid 2px $color-danger !important;
    color: $color-danger !important;
  }
  
  .beta-nav-menu {
    background: $color-white;
    padding-left: 3px;
    padding-right: 3px;
    color: $color-white;
    border-radius: 2px;
    margin-left: 4px;
    font-size: 11px;
    padding-bottom: 1px;
  }
  
  .sub-dropdown {
    position: relative;
  
    ul {
      position: absolute;
      background: $color-white;
      display: none;
    }
  
    &:hover {
      ul {
        display: block;
        right: calc(-100% + 10px);
        top: 0px;
        margin: 0px;
        padding: 0px;
        overflow: auto;
      }
    }
  }
  
  .dropdown-item.sub-dropdown::after {
    content: "\f054";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    right: 10px;
  }
  
  .categories-activity-drop-item .dropdown-item.active::after,
  .showas-activity-drop-item .dropdown-item.active::after {
    content: "\f00c";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    right: 10px;
  }
  
  .selectcolor {
    label {
      position: relative;
      display: inline-block;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-right: 8px;
      margin-bottom: 8px;
      background-color: $color-primary;
  
      &.active {
        border-color: #80bdff;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      }
  
      input {
        position: absolute;
        left: 0;
        top: 0;
        visibility: hidden;
      }
    }
  }
  
  .custom-switch {
    .custom-control-label {
      &::before {
        left: -2.25rem;
        width: 2.65rem;
        pointer-events: all;
        border-radius: 0.8rem;
        padding: 12px;
      }
  
      &::after {
        top: calc(0.25rem + 3px);
        left: calc(-2.25rem + 7px);
        width: calc(1rem - 4px);
        padding: 8px;
      }
    }
  }
  
  .custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
  }
  
  .table-scroll-x {
    overflow-x: scroll;
    height: 110px;
  }
  
  .moduleSetting {
    white-space: pre-wrap !important;
    word-break: break-all;
  }
  
  .page-info .message {
    position: relative;
    background: $color-gray-1;
    padding: 6px 14px 8px 14px;
    border-radius: 4px;
    /* line-height: 1.5; */
    display: inline-block;
  }
  
  .disable-past-dates {
    pointer-events: none;
    background: $color-light-gray;
  }
  
  .texteditor strong {
    font-weight: bold;
  }
  
  .coming-soon {
    background-color: $color-danger;
    border-radius: 5px;
    text-align: center;
    color: $color-white;
    padding: 2px;
    font-size: 8px;
    margin: 0;
  }
  
  .side-tab {
    max-width: 1200px;
    width: calc(100% - 300px);
  }
  
  .date-ctrl-rangefilter {
    max-width: 100% !important;
  }
  
  .img-profile-calender-initials {
    border-radius: 50%;
    background: $color-primary;
    height: 50px;
    width: 50px;
    color: $color-white;
    display: inline-block;
    font-size: 20px;
    padding-top: 10px;
  }
  
  .img-link-block {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    border-radius: 50%;
  }
  
  .short-name {
    background: $color-primary;
    font-size: 20px;
    color: $color-white;
    text-align: center;
    width: 50px;
    height: 50px;
  }
  
  .img-profile {
    display: inline-block;
    text-align: center;
    background: $color-primary;
    line-height: 2.4;
    color: $color-white;
  }
  
  .img-profile-grid {
    background: $color-primary;
    display: flex;
    color: $color-white;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
  }
  
  .img-profile-shop {
    display: inline-block;
    text-align: center;
    background: $color-primary;
    color: $color-white;
    height: 50px;
    width: 50px;
    max-width: 50px;
    line-height: 50px;
    font-size: 20px;
  }
  
  .swal-text {
    text-align: center !important;
  }
  
  /* satyam start */
  .acc-merge-selected {
    color: $color-danger !important;
  }
  
  /* satyam end  */
  .img-view-bg {
    max-width: 50%;     //QPC-10340 Feature added to Skip Cropper image 
    max-height: 50%;     //QPC-10340 Feature added to Skip Cropper image 
    object-fit: cover;
    cursor: pointer;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  
  .img-profile-task {
    display: inline-block;
    text-align: center;
    background: $color-primary;
    line-height: 2.4;
    color: $color-white;
    height: 32px;
    width: 32px;
  }
  
  /* VenueDemand start */
  .venuedemand-low {
    background-color: $color-success !important;
  }
  
  .venuedemand-medium {
    background-color: $color-warning-hover !important;
  }
  
  .venuedemand-high {
    background-color: $color-danger !important;
  }
  
  .venuedemand-promotional {
    background-color: $color-purple !important;
  }
  
  .month-cal-activity.venue-demand,
  .cal-activity.venue-demand {
    border-left: 2px !important;
  }
  
  /* VenueDemand End */
  .lookup-field-placeholder:empty:before {
    content: attr(data-placeholder);
    color: $color-secondary;
  }
  
  .text-overflow-ellipsis {
    @include text-hidden;
  }
  
  /* Sidebar Fixing for No Scroll*/
  #content-wrapper {
    height: 100vh;
    overflow-y: auto;
  }
  
  .tab-content .tab-pane .object-detail-right {
    border-left: 1px solid $color-gray-1;
  }
  
  /*Suraj changes for select2 border color : End*/
  .texteditor:focus {
    color: $color-light;
    background-color: $color-white;
    border-color: $color-light-blue;
    outline: 0;
    box-shadow: 0 0 0 0.15rem rgba($color-primary, 0.25);
  }
  
  .reseqeance-warning {
    border-radius: 5px;
    background-color: $light-sky-blue-2;
  }
  
  .resources-count {
    color: $color-primary;
  
    &:hover {
      color: $color-primary;
      text-decoration: underline;
    }
  }
  
  .view-tag-detail-page {
    overflow: hidden;
  }
  
  /* Pin/UnPin Menu Option */
  #sidebarpinmenu:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  
  .un-pin-icon {
    height: 2px;
    background: $color-white;
    transform: rotate(40deg);
    position: relative;
    top: -12px;
    width: 18px;
    display: block;
  }
  
  /* Pin/UnPin Menu Option */
  input:disabled[data-ui="phone"]::-webkit-input-placeholder {
    /* WebKit browsers */
    opacity: 0;
  }
  
  .btn-outline-secondary:not(:disabled):not(.disabled):active,
  .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    background-color: $color-secondary;
  }
  
  .msp-data-list .scroll-to-top {
    display: unset;
    line-height: unset;
  }
  
  /*---End List View Css----*/
  ul.miss-records {
    padding-left: 16px;
    margin-bottom: 0;
    font-weight: normal;
  }
  
  
  /* Production issue QPC-5473 */
  .tooltip {
    pointer-events: none;
  }
  
  .terms-condition-list-panel {
    height: calc(100vh - 100px);
    overflow-y: auto;
    overflow-x: hidden;
  }
  
  .event-ques-seq-table th,
  .event-ques-seq-table td {
    white-space: normal !important;
  }
  
  /* Criteria smart search for Field dropdown start */
  .smart-search-drop-filter {
    min-width: 300px !important;
  }
  
  .smart-search-drop-report {
    min-width: 249.5px !important;
  }
  
  /* Criteria smart search for Field dropdown ends */
  .eventManagerValue,
  .accountManagerValue {
    .checkbox {
      @include text-hidden;
    }
  }
  
  .calendar-dropdown {
    max-width: 152px;
  
    .date-seek {
      color: $color-primary !important;
    }
  
    .date-seek:hover {
      background-color: transparent !important;
      color: $color-primary !important;
    }
  
    .btn-outline-primary,
    .form-control {
      border-color: $color-white !important;
    }
  
    .input-group-sm > .form-control:not(textarea) {
      padding: 0.4rem 0.5rem 0.4rem 0;
      font-size: 0.875rem;
      height: auto !important;
    }
  
    .msp-popup {
      right: -15px !important;
      width: 200px;
      left: unset !important;
    }
  }
  
  /* Chrome, Safari, Edge, Opera: to remove spinner from Enter Otp Field*/
  
  /* Firefox */
  .submittedOtp {
    -moz-appearance: textfield;
  
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  
  /*otp screen css ends*/
  
  /*QPC-8590 CSS hide inner eye icon  */
  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }
  
  /*End QPC-8590 */
  .meeting-img {
    width: auto;
    height: 80px;
    display: block;
    margin: 0 auto;
  }
  
  /*Added changes for QPC-8717 start*/
  .json-display {
    background-color: $color-black;
    color: #dcdcdc;
    padding: 20px;
    border-radius: 4px;
    font-family: 'Courier New', Courier, monospace;
    font-size: 14px;
    overflow: auto;
    border: 1px solid $color-dark-hover;
    height: calc(100vh - 200px);
    box-sizing: border-box;
    margin: 0;
  }
  
  .full-screen .json-display {
    height: calc(100vh - 100px);
  }
  
  .minimize-snippit {
    margin-top: -16px;
  }
  
  .fixed-fotter {
    z-index: 10;
    height: 10px;
  }
  
  .daytime-min-w {
    max-width: 100px !important;
    width: 100%;
  }

  select.designIssue+div ul.multiselect-container.search-multiselect {
    max-height: 170px !important;
    min-height: 100px !important;
}

/*Arun Fixing bug QPC-10090 
start*/
.pms-avl-calender-ddl-wid .calendar-dropdown{
    max-width: 162px;
}
/* end */
